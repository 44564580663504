import PropTypes from 'prop-types'
import React from 'react'

import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col font-sans antialiased min-h-screen text-gray-800">
      <Header />

      <main>{children}</main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
