import React from 'react'
import { Link } from 'gatsby'
import Subtitle from '../components/Subtitle'
import logo from '../images/f2net_logo.svg'
import italyFlag from '../images/italy_flag.svg'
import googlePartnerLogo from '../images/google-partner.png'

const Footer = () => {
  return (
    <footer className="pt-12 px-12 bg-gray-100 border-t border-gray-200 text-sm">
      <div className="max-w-4xl mx-auto text-center sm:text-left px-4 flex flex-wrap justify-center sm:justify-between">
        <div className="w-full sm:w-1/3">
          <img
            alt="F2.net"
            className="block w-32 mb-4 mx-auto sm:mx-0"
            src={logo}
          />
          <p className="mb-3">
            F2 .net engineering s.r.l.
            <br />
            Via Milano, 57 - Bonate Sopra (BG)
            <br />
            <img
              alt="Italy flag"
              className="w-4 inline-block mr-2 align-baseline"
              src={italyFlag}
            />
            Italy
          </p>
          <a className="font-bold" href="mailto:info@f2.net">
            info@f2.net
          </a>
        </div>

        <div className="w-full sm:w-1/3 my-10 sm:my-0 sm:px-8">
          <Subtitle>SiteMap</Subtitle>
          <Link className="block text-gray-700 hover:text-gray-900" to="/">
            Home
          </Link>
          <Link
            className="block text-gray-700 hover:text-gray-900"
            to="/web-development"
          >
            What we do
          </Link>
          <Link
            className="block text-gray-700 hover:text-gray-900"
            to="/technology"
          >
            Technology
          </Link>
          <Link
            className="block text-gray-700 hover:text-gray-900"
            to="/projects"
          >
            Projects
          </Link>
          <Link className="block text-gray-700 hover:text-gray-900" to="/team">
            Team
          </Link>
          <Link
            className="block text-gray-700 hover:text-gray-900"
            to="/contact"
          >
            Contact
          </Link>
        </div>

        <div className="w-full sm:w-1/3 flex flex-col items-center sm:items-start justify-between">
          <div>
            <Subtitle>Legal</Subtitle>
            <p className="mb-3">
              VAT-ID IT03099890166
              <br />
              Nominal capital € 10.000
              <br />
              REA BG n° 349243
            </p>
          </div>
          <img
            src={googlePartnerLogo}
            alt="Google partner logo"
            className="w-32 border border-gray-300"
          />
          {/* <p>
            <Link className="text-gray-700 hover:text-gray-900 mr-2" to="/#">
              Privacy policy
            </Link>
            |
            <Link className="text-gray-700 hover:text-gray-900 ml-2" to="/#">
              Cookie policy
            </Link>
          </p> */}
        </div>
      </div>
      <div className="max-w-4xl mx-auto text-center sm:text-left pt-8 pb-4 px-4">
        <Link href="/contributi-pubblici">
          <a className='text-gray-500'>Contributi pubblici percepiti ai sensi della legge n. 124</a>
        </Link>
      </div>
    </footer>
  )
}

export default Footer
