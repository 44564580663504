import React from 'react'

const Subtitle = ({ children }) => {
  return (
    <div
      className="text-gray-500 font-bold text-xs uppercase mb-4"
      style={{ letterSpacing: 3 }}
    >
      {children}
    </div>
  )
}

export default Subtitle
