import React, { useState } from 'react'
import { Link } from 'gatsby'

import logo from '../images/f2net_logo.svg'

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header className="py-6 md:py-10 px-6">
      <div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto text-gray-700 font-semibold">
        <Link className="flex items-center" to="/">
          <img alt="F2.net engineering" className="w-32 mr-2" src={logo} />
        </Link>

        <button
          className="md:hidden flex items-center px-3 py-2 rounded"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:flex md:items-center w-full md:w-auto leading-loose`}
        >
          {[
            {
              route: `/web-development`,
              title: `What we do`,
            },
            {
              route: `/technology`,
              title: `Technology`,
            },
            {
              route: `/projects`,
              title: `Projects`,
            },
            {
              route: `/team`,
              title: `Team`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map(link => (
            <Link
              key={link.title}
              to={link.route}
              getProps={({ isPartiallyCurrent }) => {
                return isPartiallyCurrent
                  ? { className: 'block md:inline-block mt-4 md:mt-0 md:ml-8 text-black hover:text-black' }
                  : {
                      className:
                        'block md:inline-block mt-4 md:mt-0 md:ml-8 text-gray-600 hover:text-gray-900',
                    }
              }}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
